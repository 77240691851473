// import  bcg from '../img/bg-header.jpg'
import  bcg from '../img/bcg.svg'
export const THEME = {
  colors: {
    gray: {
      100: "#F3F4F6",
      200: "#E5E7EB",
      300: "#C3C8D0",
      400: "#C3C8D0",
      500: "#9CA3AF",
      600: "#6B7280",
      700: "#4B5563",
      800: "#374151",
      900: "#1F2937",
    },
    brand: {
      50: "#ebf8ff",
      100: "#000000",
      200: "#000000",
      300: "#ffc010",
      400: "#ffc010",
      500: "#ffc010",
      600: "#ffc010",
      700: "#ffc010",
      800: "#ffc010",
      900: "#1A365D",
    },
  },
  styles: {
    global: {
      body: {
        // fontFamily: "Montserrat Alternates",
        fontFamily: "Tektur",
        bgColor: "#000",
        // bgImage: "url(" + bcg + ")",
        // bgSize: "20px 20px",
      },
    },
  },
  shadows: {
    outline: "none",
  },
  components: {
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: "none",
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: "none",
        },
        overlay: {
          bgColor: "#000",
          // bgImage: "url(" + bcg + ")",
          // bgSize: "20px 20px",
        },
        header: {
          textAlign: "center",
        },
        content: {
          bgColor: 'red'
        }
      },
    },
    CloseButton: {
      baseStyle: {
        borderRadius: "full",
      },
    },
    Popover: {
      baseStyle: {
        content: {
          borderRadius: "xl",
          padding: 1,
        },
      },
    },
    Tooltip: {
      baseStyle: {
        fontSize: "xs",
        borderRadius: "full",
        bg: "gray.900",
        arrow: {
          bg: "gray.900",
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "none",
      },
      variants: {
        ghost: {
          color: "brand.100",
          _hover: {
            bg: "gray.200",
          },
        },
        outline: {
          borderColor: "brand.500",
          color: "brand.500",
          _hover: {
            borderColor: "brand.500",
            color: "brand.500",
            bg: "transparent",
          },
        },
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: "brand.500",
      },
    },
  },
};
