import { useContractCall } from '@usedapp/core';
// import { STAKING_CONTRACT_ADDRESS } from "constants/contracts";
import { Interface } from '@ethersproject/abi';
import { Maybe } from 'types/maybe';
import { BigNumber } from '@ethersproject/bignumber';
import { useContracts } from '@synapsenetwork/dapp-core';

export const useClaimableTokens = (
    account: Maybe<string>,
    stakingContractAddress: string, 
    stakingAbi : any
): BigNumber | undefined => {
    // const { stakingAbi } = useContracts();
    const [result] =
        useContractCall(
            account && {
                abi: new Interface(stakingAbi),
                address: stakingContractAddress,
                method: 'claimable',
                args: [account],
            }
        ) ?? [];

    return result;
};
