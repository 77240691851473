import { HStack, Text } from "@chakra-ui/layout";
import Countdown, {
  CountdownProps,
  CountdownRendererFn,
} from "react-countdown";

type LockCountdownUnitProps = {
  value: number;
};

const LockCountdownUnit: React.FC<LockCountdownUnitProps> = ({ value }) => (
  <Text fontSize="3xl">{String(value).padStart(2, "0")}</Text>
);

type LockCountdownProps = {
  date?: Date;
} & Omit<CountdownProps, "date">;

const countDownRerender: CountdownRendererFn = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) => (
  <HStack justify="center" align="center" color="brand.500" fontWeight="bold">
    <LockCountdownUnit value={completed ? 0 : days} />
    <Text>:</Text>
    <LockCountdownUnit value={completed ? 0 : hours} />
    <Text>:</Text>
    <LockCountdownUnit value={completed ? 0 : minutes} />
    <Text>:</Text>
    <LockCountdownUnit value={completed ? 0 : seconds} />
  </HStack>
);

export const LockCountdown: React.FC<LockCountdownProps> = ({
  date,
  ...rest
}) =>
  date ? (
    <Countdown date={date} renderer={countDownRerender} intervalDelay={1000} {...rest}  />
  ) : null;
