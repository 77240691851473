import { VStack, Text, HStack, Box } from '@chakra-ui/layout';
import { getShortenedAddress, TokenBalance, useTranslation } from '@synapsenetwork/dapp-core';
import copy from 'copy-to-clipboard';
import { IconButton } from '@chakra-ui/button';
import { FiCopy } from 'react-icons/fi';
import { Icon } from '@chakra-ui/icon';
import { useToast } from '@chakra-ui/toast';
import { Tooltip } from '@chakra-ui/tooltip';
import { BigNumberish } from '@ethersproject/bignumber';
import { StakingContract } from '@synapsenetwork/dapp-staking';

type AccountDetailsProps = {
    account: string;
    contract: StakingContract;
    tokenBalance?: BigNumberish;
};

export const AccountDetails: React.FC<AccountDetailsProps> =
    ({
        account,
        tokenBalance,
        contract
    }) => {
        const translate = useTranslation();
        const toast = useToast();

        const handleCopy = () => {
            copy(account);
            toast({
                description: translate('common:copiedText'),
                status: 'success',
                duration: 1500,
            });
        };

        return (
            <VStack spacing="2" align="left" textAlign="left">
                <Box>
                    <Text fontSize="xs" fontWeight="bold" className={'text'}>
                        {translate('common:address')}
                    </Text>
                    <HStack justify="space-between" position="relative">
                        <Text>{getShortenedAddress(account, 8)}</Text>
                        <Tooltip label={translate('common:copy')} placement="top">
                            <IconButton
                                aria-label="Copy"
                                variant="ghost"
                                size="sm"
                                borderRadius="full"
                                cursor="pointer"
                                position="absolute"
                                right="0"
                                top="-4px"
                                onClick={handleCopy}
                            >
                                <Icon as={FiCopy} w="4" h="4" className={'copy-icon'} />
                            </IconButton>
                        </Tooltip>
                    </HStack>
                </Box>
                <Box>
                    <Text fontSize="xs" fontWeight="bold" className={'text'}>
                        {translate('common:tokenBalance')}
                    </Text>
                    <HStack justify="space-between" position="relative">
                        <Text>
                            <TokenBalance balance={tokenBalance} decimals={contract.tokenDecimals} /> {contract.tokenTicker}
                        </Text>
                    </HStack>
                </Box>
            </VStack>
        );
    };
