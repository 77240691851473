import { Button } from '@chakra-ui/button';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import { BigNumberish } from '@ethersproject/bignumber';
import { useContractFunction } from '@usedapp/core';
import { TokenBalance, useTranslation, useContracts } from '@synapsenetwork/dapp-core';
import React, { useEffect } from 'react';
import { StakingContract } from '../../types';

type ClaimableTokensDetailsProps = {
    amount?: BigNumberish;
    canClaim: boolean;
    canRestake: boolean;
    contract: StakingContract
};

export const ClaimableTokensDetails: React.FC<ClaimableTokensDetailsProps> =
    ({
        amount,
        canClaim,
        canRestake,
        contract,
    }) => {
        const toast = useToast();
        const translate = useTranslation();
        const { stakingContract } = useContracts()

        const { state: claimState, send: claim } = useContractFunction(
            stakingContract(contract.stakingContractAddress),
            'claim'
        );
        const { state: restakeState, send: restake } = useContractFunction(
            stakingContract(contract.stakingContractAddress),
            'restake'
        );

        const handleRestake = async () => {
            await restake();
        };
        const handleClaim = async () => {
            await claim()
        };

        useEffect(() => {
            if (claimState.status === 'Success') {
                toast({
                    description: translate('toast:claimSuccess'),
                    status: 'success',
                });
            }

            if (claimState.status === 'Exception' && claimState.errorMessage) {
                toast({
                    description: claimState.errorMessage,
                    status: 'error',
                });
            }
        }, [claimState.status, translate, toast]);

        useEffect(() => {
            if (restakeState.status === 'Success') {
                toast({
                    description: translate('toast:restakeSuccess'),
                    status: 'success',
                });
            }

            if (restakeState.status === 'Exception' && restakeState.errorMessage) {
                toast({
                    description: restakeState.errorMessage,
                    status: 'error',
                });
            }
        }, [restakeState.status, translate, toast]);

        return (
            <HStack justify="space-between">
                <VStack align="start" spacing="0">

                    <Text fontSize="xs" fontWeight="bold" className={'text'}>
                        {translate('step:stake:earnedTokens')}
                    </Text>
                    <Text fontWeight="bold" fontSize="xl">
                        <TokenBalance balance={amount} decimals={contract.tokenDecimals} /> {contract.rewardTicker}
                    </Text>
                </VStack>
                {/* <HStack align="end">
                    <Button
                        variant="ghost"
                        size="sm"
                        isDisabled={!canClaim || claimState.status === 'Mining'}
                        isLoading={claimState.status === 'Mining'}
                        onClick={handleClaim}
                        className={'claim-button'}
                    >
                        {translate('common:claim')}
                    </Button>
                    {contract.canRestake && <Button
                        colorScheme="brand"
                        size="sm"
                        isDisabled={!canRestake || restakeState.status === 'Mining'}
                        isLoading={restakeState.status === 'Mining'}
                        onClick={handleRestake}
                        className={'restake-button'}
                    >
                        {translate('common:restake')}
                    </Button>}
                </HStack> */}
            </HStack>
        );
    };
