import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { HStack, Text, VStack,Heading } from '@chakra-ui/layout';
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { useContractFunction } from '@usedapp/core';
import { ClaimableTokensDetails } from 'components/stake/ClaimableTokensDetails';
import { SubmitButton, Separator, TokenBalance, ModalInfo, useTranslation, useContracts } from '@synapsenetwork/dapp-core';
import React, { useEffect } from 'react';
import { FiInfo } from 'react-icons/fi';
import { useToast } from '@chakra-ui/toast';
import { StakeInfo, StakingContract } from '../../types';
import { number } from 'yup';
import { LockCountdown } from './LockCountdown';

type StakeDetailsProps = {
    contract: StakingContract;
    stakeInfo?: StakeInfo;
    stakedTokens?: BigNumber;
    claimableTokens?: BigNumber;
    tokenBalance?: BigNumber;
    aprValue?: BigNumberish;
    endDate?: string;
    buttonsPaddingTop?: number;
    onStakeClick: () => void;
    onRequestUnstakeClick: () => void;
    hideStake: boolean;
};

export const StakeDetails: React.FC<StakeDetailsProps> =
    ({
        stakeInfo,
        aprValue,
        stakedTokens,
        claimableTokens,
        tokenBalance,
        onStakeClick,
        contract,
        buttonsPaddingTop,
        endDate, 
        hideStake

    }) => {
        const toast = useToast();
        const translate = useTranslation();
        const { stakingContract } = useContracts();

        const canStake = Boolean(// TODO -> sprawdzić czy ma jakieś tokeny zastakowane
            !!stakeInfo && tokenBalance && !tokenBalance?.eq(0) && stakeInfo.tokens.eq(0)
        );



        const { state: claimRewardsState, send: claimRewards } = useContractFunction(
            stakingContract(contract.stakingContractAddress),
            'claim'
        );


        useEffect(() => {
            if (claimRewardsState.status === 'Success') {
                toast({
                    description: translate('toast:claimSuccess'),
                    status: 'success',
                });
            }

            if (
                claimRewardsState.status === 'Exception' &&
                claimRewardsState.errorMessage
            ) {
                toast({
                    description: claimRewardsState.errorMessage,
                    status: 'error',
                });
            }
        }, [claimRewardsState.status, translate, toast]);

        return (
            <>
                <VStack justify="stretch" align="stretch" spacing="4" mb="8">
                    <Separator />

                    {contract.showApr ? <HStack justify="space-between">
                        <HStack spacing="2">
                            <Text>{translate('common:apr')}</Text>
                            <ModalInfo title={translate('aprInfo:title')}
                                description={translate('aprInfo:message')}>
                                <Icon as={FiInfo} className={'info-icon'} w="4" h="4" />
                            </ModalInfo>
                        </HStack>
                        <Text fontWeight="bold">{aprValue?.toString() || 0}%</Text>
                    </HStack> : <HStack justify="space-between">
                        <HStack spacing="2">
                            <Text>-</Text>

                        </HStack>
                    </HStack>

                    }

                    <Separator />

                    <HStack justify="space-between">
                        <VStack align="start" spacing="0">
                            <Text fontSize="xs" fontWeight="bold" className={'text'}>
                                {translate('step:stake:stakedTokens')}
                            </Text>
                            <Text fontWeight="bold" fontSize="xl">
                                <TokenBalance balance={stakedTokens} decimals={contract.tokenDecimals} /> {contract.tokenTicker}
                            </Text>
                        </VStack>

                    </HStack>
                    <Separator />

                    <ClaimableTokensDetails
                        amount={claimableTokens}
                        canClaim={false}
                        canRestake={false}
                        contract={contract}
                    />

                    <Separator />
                    {contract.isFixedApr && contract.showEndDate &&
                        <>
                            <HStack justify="space-between">
                                <VStack align="start" spacing="0">
                                    <Text fontSize="xs" fontWeight="bold" className={'text'}>
                                        Staking end date
                                    </Text>
                                    <Text fontWeight="bold" fontSize="xl">
                                        {endDate}
                                    </Text>
                                </VStack>
                            </HStack>
                            <Separator />
                        </>
                    }
                </VStack>

                <VStack spacing="4" paddingTop={buttonsPaddingTop ? buttonsPaddingTop : 0}>
                    { !hideStake &&   <SubmitButton
                        position="relative"
                        onClick={onStakeClick}
                        isDisabled={!canStake}
                        className={'stake-button'}
                    >
                        {translate('common:stake')}
                    </SubmitButton> }
                    {
                        stakeInfo?.withdrawalPossibleAt &&  stakeInfo.tokens.gt(0) && 
                        <>
                            <Heading fontSize="2xl" textAlign="center">{translate('withdraw:locked:title')}</Heading>
                            <LockCountdown date={new Date(stakeInfo?.withdrawalPossibleAt?.toNumber() * 1000)} />
                        </>
                    }
                </VStack>
            </>
        );
    };
