import { Box, Heading, Text, VStack } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import { useContractFunction } from '@usedapp/core';
import React, { useEffect } from 'react';
import { FiCheck } from 'react-icons/fi';
import { StakeInfo } from 'types/StakeInfo';
import { StakingContract } from 'types/StakingContract';
import { IconInCircle, SubmitButton, TokenBalance, useContracts, useTranslation } from '@synapsenetwork/dapp-core';

type ReadyStateDetailsProps = {
    stakeInfo?: StakeInfo;
    contract: StakingContract
};

export const ReadyStateDetails: React.FC<ReadyStateDetailsProps> =
    ({
         stakeInfo,
         contract
     }) => {
        const toast = useToast();
        const translate = useTranslation();
        const { stakingContract } = useContracts();

        const {state: unstakeState, send: unstake} = useContractFunction(
            stakingContract(contract.stakingContractAddress),
            'unstake'
        );

        const handleUnstake = () => {
            unstake();
        };

        useEffect(() => {
            if (unstakeState.status === 'Success') {
                toast({
                    description: translate('toast:unstakeSuccess'),
                    status: 'success',
                });
            }

            if (unstakeState.status === 'Exception' && unstakeState.errorMessage) {
                toast({
                    description: unstakeState.errorMessage,
                    status: 'error',
                });
            }
        }, [unstakeState.status, translate, toast]);

        return (
            <VStack spacing="8" py="12">
                <IconInCircle icon={FiCheck}/>
                <VStack>
                    <Heading fontSize="3xl" textAlign="center">
                        {translate('withdraw:ready:title')}
                    </Heading>
                    <Text>{translate('withdraw:ready:message')}</Text>
                </VStack>

                <Box w="full" py="8" px="6">
                    <VStack spacing="3">
                        <Text fontSize="2xl" fontWeight="semibold">
                            <TokenBalance balance={stakeInfo?.tokens} decimals={contract.tokenDecimals}/> {contract.tokenTicker}
                        </Text>
                    </VStack>
                </Box>

                <Box px="6" w="full">
                    <SubmitButton
                        onClick={handleUnstake}
                        isDisabled={unstakeState.status === 'Mining'}
                        isLoading={unstakeState.status === 'Mining'}
                    >
                        {translate('common:unstake')}
                    </SubmitButton>
                </Box>
            </VStack>
        );
    };
