import { ChainId } from "@usedapp/core";

export const SUPPORTED_CHAINS_IDS = [ChainId.BSC];

export const CHAIN_PARAMS: { [key in ChainId]?: any } = {
  [ChainId.BSC]: {
    chainId: `0x${ChainId.BSC.toString(16)}`,
  },
};


// export const SUPPORTED_CHAINS_IDS = [ChainId.Mumbai];

// export const CHAIN_PARAMS: { [key in ChainId]?: any } = {
//   [ChainId.Mumbai]: {
//     chainId: `0x${ChainId.Mumbai.toString(16)}`,
//   },
// };


// export const SUPPORTED_CHAINS_IDS = [ChainId.BSCTestnet];

// export const CHAIN_PARAMS: { [key in ChainId]?: any } = {
//   [ChainId.BSCTestnet]: {
//     chainId: `0x${ChainId.BSCTestnet.toString(16)}`,
//   },
// };
