import { FiCheck, FiInfo } from 'react-icons/fi';
import { HStack, Text, VStack } from '@chakra-ui/layout';
import { Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import {
    ERC20Interface,
    useContractFunction,
    useEthers,
    useTokenAllowance,
} from '@usedapp/core';
import { useCallback, useEffect, useState } from 'react';
import { Icon } from '@chakra-ui/icon';
import { useToast } from '@chakra-ui/toast';
import { BorderedBox, Separator, SubmitButton, ModalInfo, BigNumberRangeSlider, BigNumberInput, useTranslation } from '@synapsenetwork/dapp-core';
import { StakingContract } from '../../types';
import { useContracts } from 'hooks/useContracts';

type StakeFormProps = {
    tokenBalance?: BigNumberish;
    aprValue?: BigNumberish;
    contract: StakingContract;
    onSuccess: () => void;
    stackingAbi: any;
};

export const StakeForm: React.FC<StakeFormProps> =
    ({
        aprValue = '0',
        tokenBalance = '0',
        onSuccess,
        contract,
        stackingAbi
    }) => {
        const toast = useToast();
        const translate = useTranslation();
        const { tokenContract, stakingContract } = useContracts(stackingAbi);

        const { account } = useEthers();
        const tokensApproved = useTokenAllowance(
            contract.tokenContractAddress,
            account,
            contract.stakingContractAddress
        );

        const [tokenAmount, setTokenAmount] = useState<BigNumber>(BigNumber.from(0));
        const [isInputVisible, setIsInputVisible] = useState(false);
        const [stakingPool, setStakingPool] = useState('0');
        const canApprove = tokenAmount.gt(0);
        const canStake = tokenAmount.gt(0) && tokensApproved?.gte(tokenAmount);

        const { state: approveState, send: approve } = useContractFunction(
            tokenContract(contract.tokenContractAddress, ERC20Interface),
            'approve'
        );

        const { state: addStakeState, send: addStake } = useContractFunction(
            stakingContract(contract.stakingContractAddress,),
            'addStake'
        );
 
        const handleApprove = () => {
            approve(contract.stakingContractAddress, tokenAmount?.toString());
        };

        const handleAddStake = useCallback(async () => {
            addStake(tokenAmount?.toString(), stakingPool);
        }, [addStake, tokenAmount]);

        const handleChange = useCallback(
            (value: BigNumberish) => {
                setTokenAmount(BigNumber.from(value));
            },
            [setTokenAmount]
        );


        const handleInputVisibilityToggle = () => {
            setIsInputVisible(!isInputVisible);
        };

        useEffect(() => {
            if (approveState.status === 'Success') {
                toast({
                    description: translate('toast:approveSuccess'),
                    status: 'success',
                });
            }

            if (approveState.status === 'Exception' && approveState.errorMessage) {
                toast({
                    description: approveState.errorMessage,
                    status: 'error',
                });
            }
        }, [approveState.status, translate, toast]);

        useEffect(() => {
            if (addStakeState.status === 'Success') {
                toast({
                    description: translate('toast:stakeSuccess'),
                    status: 'success',
                });
                onSuccess();
            }

            if (addStakeState.status === 'Exception' && addStakeState.errorMessage) {
                toast({
                    description: addStakeState.errorMessage,
                    status: 'error',
                });
            }
        }, [addStakeState.status, translate, toast, onSuccess]);

        return (
            <VStack spacing="6">
                <BorderedBox p="4">
                    <VStack align="stretch">
                        <HStack justify="space-between">
                            <VStack w="full" align="start" spacing="0">
                                <Text fontSize="xs" fontWeight="bold" color="gray.500">
                                    {translate('common:stake')}
                                </Text>

                                <HStack w="full" justify="space-between">
                                    {/*{isInputVisible ? (*/}
                                    <BigNumberInput
                                        my="1"
                                        size="lg"
                                        variant="unstyled"
                                        max={tokenBalance}
                                        value={tokenAmount}
                                        autoFocus
                                        isDisabled={
                                            approveState.status === 'Mining'
                                            || addStakeState.status === 'Mining'
                                        }
                                        onChange={handleChange}
                                    />
                                </HStack>
                            </VStack>
                        </HStack>

                        <Separator />

                        <HStack justify="space-between">
                            <HStack spacing="1">
                                <Text fontSize="sm">{translate('common:apr')}</Text>
                                <ModalInfo title={translate('aprInfo:title')}
                                    description={translate('aprInfo:message')}>
                                    <Icon as={FiInfo} className={'info-icon'} w="4" h="4" />
                                </ModalInfo>
                            </HStack>
                            <Text fontSize="sm" fontWeight="semibold">
                                {aprValue.toString()}%
                            </Text>
                        </HStack>
                    </VStack>
                </BorderedBox>

                <BigNumberRangeSlider
                    value={tokenAmount}
                    max={tokenBalance}
                    isLocked={
                        approveState.status === 'Mining'
                        || addStakeState.status === 'Mining'
                    }
                    onChange={handleChange}
                ></BigNumberRangeSlider>

                <VStack w="full" alignItems="left">
                    <Text fontSize='xl'> Choose stking pool </Text>
                    <RadioGroup onChange={setStakingPool} value={stakingPool} >
                        <VStack spacing="2" alignItems="left">
                            <Radio value='1'>apr 1,5% - 3 month lock</Radio>
                            <Radio value='2'>apr 3% - 6 month lock</Radio>
                            <Radio value='3'>apr 7,5% - 12 month lock</Radio>
                        </VStack>
                    </RadioGroup>
                    <SubmitButton
                        isLoading={approveState.status === 'Mining'}
                        isDisabled={
                            approveState.status === 'Mining' || canStake || !canApprove
                        }
                        onClick={handleApprove}
                        leftIcon={canStake ? <Icon as={FiCheck} /> : undefined}
                        className={'approve'}
                    >
                        {translate('common:approve')}
                    </SubmitButton>

                    <SubmitButton
                        isLoading={addStakeState.status === 'Mining'}
                        isDisabled={
                            addStakeState.status === 'Mining' || !canStake || !canApprove || stakingPool === '0'
                        }
                        onClick={handleAddStake}
                        className={'confirm'}
                    >
                        {translate('common:confirm')}
                    </SubmitButton>
                </VStack>
            </VStack>
        );
    };
