import { Box, HStack, VStack } from '@chakra-ui/layout';
import { CONTRACTS } from './constants/contracts';

import { WalletErrors, Footer, ConnectView, IconInCircle } from '@synapsenetwork/dapp-core';
import 'App.css'
import logo from 'img/logo.png'
import { AppState } from '@synapsenetwork/dapp-core';
import { useEffect, useState } from 'react';
import { useEthers } from '@usedapp/core';
import { BsExclamation } from 'react-icons/all';
import lpMining from 'abis/lpMining.abi.json';
import uniswapV2PairAbi from 'abis/uniswapV2Pair.abi.json';
import { Slide } from '@chakra-ui/react';
import { StakeViewLock } from 'views/StakeViewLock';
import { StakeView } from '@synapsenetwork/dapp-staking';

import stackingLockAbi from 'abis/stakingFixedLockAndApr.json';




// export enum AppState {
//     Connect = 'connect',
//     Stake = 'stake',
//     Withdraw = 'withdraw',
//     Loading = 'loading',
// }

const App: React.FC = () => {
    const [currentState, setCurrentState] = useState(AppState.Loading);
    const {account, active} = useEthers();
    // useEffect(() => {
    //     if (!active) {
    //         setCurrentState(AppState.Connect);
    //     }
    // }, [active]);
    return (
        <div className={'xxx'}>
            <HStack>
                <VStack spacing={12} my={12} alignItems="center" w="full" px={4}>
                    {/*<VStack maxW="380px" w="full" spacing="8">*/}
                    {/*<Box w="full" paddingBottom="6">*/}

                        {logo && <img src={logo} width={184}/>}

                    {/*</Box>*/}
                    {/*</VStack>*/}
                </VStack>
            </HStack>
            {!active && <HStack>
                <VStack spacing={12} my={12} alignItems="center" w="full" px={4}>
                    <VStack maxW="380px" w="full" spacing="8">
                        <Box w="full" paddingBottom="6">

                            <ConnectView/>
                        </Box></VStack></VStack>
            </HStack>}
            {active &&
            <HStack>
                <VStack spacing={12} my={12} alignItems="center" w="full" px={4}>
                    <VStack maxW="380px" w="full" spacing="8">
                        <Box w="full" paddingBottom="6">
                            <StakeView 
                            contract={CONTRACTS.CONTRACT1} 
                            logo={logo}
                            headerText={'Standard Staking'}
                            />
                        </Box>
                    </VStack>
                </VStack>
                <VStack spacing={12} my={12} alignItems="center" w="full" px={4}>
                    <VStack maxW="380px" w="full" spacing="8">
                        <Box w="full" paddingBottom="6">
                            <StakeViewLock
                            contract={CONTRACTS.CONTRACT5} 
                            logo={logo}
                            stackingAbi={stackingLockAbi}
                            headerText={'GAMETY airdrop staking pool'}
                            />
                        </Box>
                    </VStack>
                </VStack>
           
                <VStack spacing={12} my={12} alignItems="center" w="full" px={4}>
                    <VStack maxW="380px" w="full" spacing="8">
                        <Box w="full" paddingBottom="6">
                            <StakeView
                                contract={CONTRACTS.CONTRACT2}
                                logo={logo}
                                uniswapV2PairAbi={uniswapV2PairAbi}
                                lpMining={lpMining}
                                headerText={'VIP Staking'}
                            />
                        </Box>
                    </VStack>
                </VStack>

                 <VStack spacing={12} my={12} alignItems="center" w="full" px={4}>
                    <VStack maxW="380px" w="full" spacing="8">
                        <Box w="full" paddingBottom="6">
                            <StakeView
                                contract={CONTRACTS.CONTRACT3}
                                logo={logo}
                                uniswapV2PairAbi={uniswapV2PairAbi}
                                lpMining={lpMining}
                                buttonsPaddingTop={0}
                                headerText={'LP Staking'}
                            />
                        </Box>
                    </VStack>
                </VStack>  
                <VStack spacing={12} my={12} alignItems="center" w="full" px={4}>
                    <VStack maxW="380px" w="full" spacing="8">
                        <Box w="full" paddingBottom="6">
                            <StakeView 
                            contract={CONTRACTS.CONTRACT4} 
                            logo={logo}
                            headerText={'Tesla Contest Staking'}
                            />
                        </Box>
                    </VStack>
                </VStack>
               


            </HStack>}
            {/* <Footer/> */}
            <WalletErrors icon={ <IconInCircle icon={BsExclamation} colorScheme="brand" iconW={90}  iconH={90}/>} />
            <Slide direction="bottom" in={true}  >
                            <Footer />
        </Slide>
        </div>
    );
};
export default App;
