import { useContractCall } from "@usedapp/core";
import { Interface } from "@ethersproject/abi";
import { Maybe } from "types/maybe";
import { StakeInfo } from '../types';

export const useStakeInfo = (account: Maybe<string>, stakingContractAddress: string,stkingAbi: any): StakeInfo | undefined => {
  const result: any = useContractCall(
    account && {
      abi: new Interface(stkingAbi),
      address: stakingContractAddress,
      method: "tokenStake",
      args: [account],
    }
  );
  if (!result) return;
  return {
    tokens: result.tokens,
    withdrawalPossibleAt: result.withdrawalPossibleAt,
    pool: result.pool, 
    stakeStart: result.stakeStart
  };
};
