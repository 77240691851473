import { useCallback, useContext } from "react";
import { Contract } from '@ethersproject/contracts';
import { Interface } from '@ethersproject/abi';

export const useContracts = (stakingAbi :any) => {
    
    const stakingContract = useCallback((contractAddress: string) => {
        return new Contract(
            contractAddress,
            new Interface(stakingAbi)
        );
    }, [stakingAbi]);

    const tokenContract = useCallback((contractAddress : string, abi: Interface) => {
        return new Contract(contractAddress, abi)
    }, []);

    return {

        stakingAbi, stakingContract, tokenContract
    };
}
