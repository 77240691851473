import { StakingContract } from '@synapsenetwork/dapp-staking';


export const CONTRACTS: { [key: string]: StakingContract } = {
    CONTRACT1: {
        tokenContractAddress: '0xBb6cDedac5CaB4A420211a4A8e8B5DCA879B31De',
        tokenTicker: 'MF',
        rewardTicker: 'MF',
        stakingContractAddress: '0xBebCBc176Ebb8C44c11f816D28308Ed6B3F951D3',
        canRestake: true,
        showApr: true,
        showConnect: true,
        tokenDecimals: 4,
        isLp: false,
        isFixedApr: true,
        showEndDate: false
    },
    CONTRACT2: {
        tokenContractAddress: '0xBb6cDedac5CaB4A420211a4A8e8B5DCA879B31De',
        tokenTicker: 'MF',
        rewardTicker: 'MF',
        stakingContractAddress: '0x2E9dB9a0dC04183FDE1E7bd5ad59caA7a4B2a631',
        canRestake: true,
        showApr: true,
        showConnect: true,
        tokenDecimals: 4,
        isLp: false,
        isFixedApr: true,
        showEndDate: false

    },
    CONTRACT3: {
        tokenContractAddress: '0xfa529983f31a56396098b60d0347e4db61d359b8', // Staking Token (TSTLP):
        tokenTicker: 'LP-MF',
        rewardTicker: 'MF',
        stakingContractAddress: '0x23c4fb1c3896324F6b489ACBE276FaCc9296A365', //LPMining contract
        canRestake: false,
        showApr: true,
        showConnect: true,
        tokenDecimals: 4,
        isLp: true,
        LP_MINING_CONTRACT_ADDRESS: '0x23c4fb1c3896324F6b489ACBE276FaCc9296A365',
        UNISWAP_V2_PAIR_CONTRACT_ADDRESS: '0xfa529983f31a56396098b60d0347e4db61d359b8', // Staking Token (TSTLP)
        useReserve0: false,
        isFixedApr: false,
        showEndDate: false
    },
    CONTRACT4: {
        tokenContractAddress: '0xBb6cDedac5CaB4A420211a4A8e8B5DCA879B31De',
        tokenTicker: 'MF',
        rewardTicker: 'MF',
        stakingContractAddress: '0x33c0d179d5521cCE7ad124D5Cc8dA94004148CAa',
        canRestake: false,  
        showApr: true,
        showConnect: true,
        tokenDecimals: 4,
        isLp: false,
        isFixedApr: true,
        showEndDate: false
    },
    CONTRACT5: {
        tokenContractAddress: '0xBb6cDedac5CaB4A420211a4A8e8B5DCA879B31De',
        tokenTicker: 'MF',
        rewardTicker: 'MF',
        stakingContractAddress: '0xFC2790fedAC1ee9877752B8198e971E433Cf4e10',
        canRestake: false,  
        showApr: true,
        showConnect: true,
        tokenDecimals: 4,
        isLp: false,
        isFixedApr: true,
        showEndDate: false
    }
}

