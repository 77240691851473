import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Config } from '@usedapp/core';
import { extendTheme } from '@chakra-ui/react';
import { THEME } from 'constants/theme';
import { DappStakingProvider } from '@synapsenetwork/dapp-staking';
import { BrowserRouter as Router } from 'react-router-dom';
import stakingAbi from 'abis/staking.abi.json';
import lpMining from 'abis/lpMining.abi.json';
import uniswapV2PairAbi from 'abis/lpMining.abi.json';
import { ERC20Interface } from "@usedapp/core";
import { CHAIN_PARAMS, SUPPORTED_CHAINS_IDS } from 'constants/chains';
import en from '../public/lang/en.json';


const config: Config = {};
const theme = extendTheme(THEME);

ReactDOM.render(
    <React.StrictMode>
        <DappStakingProvider
            accessToken={'1245'}
            chakraTheme={theme}
            dappConfig={config} contracts={{
                stakingAbi,
                tokenAbi: ERC20Interface,
                lpMining,
                uniswapV2PairAbi

            }}
            chains={{
                supportedChainIds: SUPPORTED_CHAINS_IDS,
                chainParams: CHAIN_PARAMS,
            }}
            translations={en}
        >
            <Router>
                <App />
            </Router>
        </DappStakingProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
