import { Center, Heading, VStack } from '@chakra-ui/layout';
import { useEthers } from '@usedapp/core';
import { AccountDetails } from '../components/stake/AccountDetails';
import { useDisclosure } from '@chakra-ui/hooks';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/modal';
import { StakeForm } from 'components/stake/StakeForm';
import { useTokensBalance } from 'hooks/useTokensBalance';
import { useClaimableTokens } from 'hooks/useClaimableTokens';
import { StakeDetails } from 'components/stake/StakeDetails';
import { FilledBox, AppState, useTranslation, ConnectView } from '@synapsenetwork/dapp-core';
import { Spinner } from '@chakra-ui/spinner';
import { useEffect, useMemo, useState } from 'react';
import { useStakeInfo } from '../hooks/useStakeInfo';
import { StakingContract } from '../types';
import { ReadyStateDetails } from 'components/withdraw/ReadyStateDetails';

type StakeViewLockProps = {
    contract: StakingContract,
    logo: string,
    lpMining?: any,
    uniswapV2PairAbi?: any,
    buttonsPaddingTop?: number,
    headerText?: string,
    stackingAbi: any

}

export const StakeViewLock: React.FC<StakeViewLockProps> = ({
    contract,
    logo,
    uniswapV2PairAbi,
    lpMining,
    buttonsPaddingTop,
    headerText,
    stackingAbi
}) => {
    const { active, account, setError } = useEthers();
    const stakeInfo = useStakeInfo(account, contract.stakingContractAddress, stackingAbi);

    const [isReady, setIsReady] = useState(false);
    const readyAt = useMemo(() => {
        if (!stakeInfo?.withdrawalPossibleAt) {
            return;
        }

        return new Date(stakeInfo?.withdrawalPossibleAt?.toNumber() * 1000);
    }, [stakeInfo?.withdrawalPossibleAt]);

    useEffect(() => {
        if (readyAt && (readyAt.getTime() !== new Date(0).getTime()) && readyAt?.getTime() < new Date().getTime()) {
            setIsReady(true);
        }
    }, [readyAt]);

    const translate = useTranslation();
    const tokenBalance = useTokensBalance(account, contract.tokenContractAddress);
    const claimableTokens = useClaimableTokens(account, contract.stakingContractAddress, stackingAbi);

    let fixedAprValue = 'up to 7.5';
    let endDate = '';
    if (stakeInfo && stakeInfo.tokens.gt(0)) {
        switch (stakeInfo.pool) {
            case 1:
                fixedAprValue = '1.5';
                break;
            case 2:
                fixedAprValue = '3.0';
                break;
            case 3:
                fixedAprValue = '7.5';
                break;
        }


    }

    const [currentState, setCurrentState] = useState(AppState.Loading);

    const isLoaded = !!stakeInfo;


    const {
        isOpen: isStakeModalOpen,
        onOpen: onStakeModalOpen,
        onClose: onStakeModalClose,
    } = useDisclosure();

    const {
        isOpen: isRequestUnstakeModalOpen,
        onOpen: onRequestUnstakeModalOpen,
        onClose: onRequestUnstakeModalClose,
    } = useDisclosure();

    useEffect(() => {
        if (!active) {
            setCurrentState(AppState.Connect);
        }
    }, [active]);

    useEffect(() => {
        if (!active) {
            setCurrentState(AppState.Connect);
            return;
        } else if (isLoaded) {
            if (isReady && claimableTokens?.gt(0) ) {
                setCurrentState(AppState.Withdraw);

            } else {
                setCurrentState(AppState.Stake);
            }

        }
        else {
            setCurrentState(AppState.Loading);
        }
    }, [active, isLoaded, isReady, claimableTokens, setCurrentState]);

    return (
        <>

            {currentState === AppState.Connect && contract.showConnect && <ConnectView />}
            {currentState === AppState.Stake && (

                <FilledBox className={'filled-box outer'}>
                    <VStack justify="stretch" align="stretch" spacing="4" mb="8">
                        <Heading fontSize="2xl" textAlign="center">
                            {headerText}
                        </Heading>
                    </VStack>
                    {account && tokenBalance && (
                        <AccountDetails
                            account={account}
                            tokenBalance={tokenBalance}
                            contract={contract}
                        />
                    )}
                    <>
                        <StakeDetails
                            stakeInfo={stakeInfo}
                            tokenBalance={tokenBalance}
                            stakedTokens={stakeInfo?.tokens}
                            claimableTokens={claimableTokens}
                            aprValue={fixedAprValue}
                            onStakeClick={onStakeModalOpen}
                            onRequestUnstakeClick={onRequestUnstakeModalOpen}
                            contract={contract}
                            buttonsPaddingTop={buttonsPaddingTop}
                            endDate={endDate}
                            hideStake = {true}
                        />
                    </>
                </FilledBox>)}
            {currentState === AppState.Withdraw && (
                <FilledBox className={'filled-box outer'}>
                    <ReadyStateDetails stakeInfo={stakeInfo} contract={contract} />

                </FilledBox>)}

            {currentState === AppState.Loading && (
                <Center h="500px" w="full">
                    <Spinner
                        size="xl"
                        emptyColor="gray.200"
                        color="brand.500"
                        thickness="4px"
                    />
                </Center>
            )}

            <Modal isOpen={isStakeModalOpen} onClose={onStakeModalClose} size="sm"
                colorScheme="brand">
                <ModalOverlay />
                <ModalContent borderRadius="none"
                    className={'modal-content'}
                >
                    <ModalHeader pb="4" pt="8" fontSize="2xl">
                        {translate('common:stake')}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pt="0" pb="10">
                        <StakeForm
                            tokenBalance={tokenBalance}
                            aprValue={fixedAprValue}
                            onSuccess={onStakeModalClose}
                            contract={contract}
                            stackingAbi = {stackingAbi}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>


        </>
    );
};
